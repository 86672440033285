import jQuery from "./export-jquery";
import {WOW} from 'wowjs';
import flexslider from 'flexslider';

document.addEventListener("DOMContentLoaded", function(){
   console.log('hi');

   var wow = new WOW({
      live: false
   }).init();

   document.getElementById('menu').addEventListener('click', function(){
      var x = document.getElementById('menu');
      x.classList.toggle("change");
   });
});

   $(window).on('load', function() {
      $('.flexslider').flexslider({
         animation: "slide",
         slideshowSpeed: 11000
      });
   });

	$(window).ready(function() {

        $("#nav").addClass("js");
        $("#menu").click(function(){
            $("#nav").toggle();
        });
        $(window).resize(function(){
            if(window.innerWidth > 768) {
                $("#nav").removeAttr("style");
            }
        });
		
		/*************************/
		/* SLIDING CONTENT
		/*************************/
        
        $.fn.showHide = function (options) {

		//default vars for the plugin
        var defaults = {
            speed: 1000,
			easing: '',
			changeText: 0,
			showText: 'Show',
			hideText: 'Hide'
			
        };
        var options = $.extend(defaults, options);

        $(this).click(function () {	
           
                 $('.toggleDiv').slideUp(options.speed, options.easing);	
                 // this var stores which button you've clicked
                 var toggleClick = $(this);
                 // this reads the rel attribute of the button to determine which div id to toggle
                 var toggleDiv = $(this).attr('rel');
                 // here we toggle show/hide the correct div at the right speed and using which easing effect
                 $(toggleDiv).slideToggle(options.speed, options.easing, function() {
                 // this only fires once the animation is completed
                 if(options.changeText==1){
                 $(toggleDiv).is(":visible") ? toggleClick.html(options.hideText) : toggleClick.html(options.showText);
                 }
                  });

              return false;

            });

        };
        
         $('.show_hide').showHide({			 
            speed: 500,  // speed you want the toggle to happen	
            easing: '',  // the animation effect you want. Remove this line if you dont want an effect and if you haven't included jQuery UI
            changeText: 1, // if you dont want the button text to change, set this to 0
            showText: '<i class="fa fa-chevron-circle-down" aria-hidden="true"></i>View Bio',// the button text to show when a div is closed
            hideText: '<i class="fa fa-times-circle-o" aria-hidden="true"></i>Close' // the button text to show when a div is open

        }); 

	});
        
		/*************************/
		/* SEARCH FORM
		/*************************/
		// var $wrapper = $('.search-icon');
		// var $button = $wrapper.find( 'a.search');
		// var $icon = $button.find('span');
		// var $input = $wrapper.find( '.search-field-wrapper' );
		// var $window = $(window);
		// var inputIsVisible = false;
		
		// if ( $window.width() > 767 ) {
		// 	$input.width( 0 );
		// } else {
		// 	inputIsVisible = true;
		// 	createRotatorSelectorSlider();
		// }
		
		
		
		/*************************/
		/* WINDOW RESIZE
		/*************************/
		
		// var prevSize = $window.width();
		// $window.on('resize',function() {
		// 	var newSize = $window.width();
		// 	if ( prevSize > 767 && newSize <= 767 ) {
		// 		// going into mobile
		// 		$input.removeAttr('style');
		// 		inputIsVisible = true;
		// 		createRotatorSelectorSlider()
		// 	}
		// 	if ( prevSize <= 767 && newSize > 767 ) {
		// 		// coming out of mobile
		// 		$input.css({'width':0});
		// 		$button.removeClass('search-active');
		// 		inputIsVisible = false; 
		// 		destroyRotatorSelectorSlider();
		// 	}
		// 	prevSize = newSize;
		// });
		
		
		
		
		
		
		// $('body').on( 'click', '.site-wrapper', function(e) {
		// 	if ( $input.is(':visible') ) {
		// 		hideSearch();
		// 	}
		// });
		
		
		
		
		/*************************/
		/* PLACEHOLDER FALLBACK
		/*************************/
		// function elementSupportsAttribute( element, attribute ) {
		// 	var test = document.createElement(element);
		// 	if ( attribute in test ) {
		// 		return true;	
		// 	} else {
		// 		return false;
		// 	}
		// }
		// // Check if placeholder attribute is supported
		// if ( !elementSupportsAttribute( 'input', 'placeholder' ) ) {
		// 	// if not replace with javascript
		// 	$("[placeholder]").focus(function() {
		// 	  var input = $(this);
		// 	  if (input.val() == input.attr("placeholder")) {
		// 		input.val("");
		// 		input.removeClass("placeholder");
		// 	  }
		// 	}).blur(function() {
		// 	  var input = $(this);
		// 	  if (input.val() == "" || input.val() == input.attr("placeholder")) {
		// 		input.addClass("placeholder");
		// 		input.val(input.attr("placeholder"));
		// 	  }
		// 	}).blur();
		// 	$("[placeholder]").parents("form").submit(function() {
		// 	  $(this).find("[placeholder]").each(function() {
		// 		var input = $(this);
		// 		if (input.val() == input.attr("placeholder")) {
		// 		  input.val("");
		// 		}
		// 	  })
		// 	});
		// }
        
    

      
       

/***************************************************************************/
/* ANIMATED ANCHORS
/***************************************************************************/
var $page = $("html, body");
var scrollPositionOffset = 30; // offset the scroll position so that the element is not right at the top of the screen

// Click handler to scroll to # targets within the page.
// use a class of .no-scroll on the anchor tag to prevent an href="#someID" from scrolling
$('a[href*="#"]:not([href="#"])').not('a.no-scroll').on('click', function(e) {
    // Make sure the href="#someID" is on this page by comparing the host and path names of the href and the current location
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        e.preventDefault();
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
            $page.animate({ 
                scrollTop: target.offset().top - scrollPositionOffset + 'px'
            }, 1000);
        }
    }
});

/***************************************************************************/
/* INITAL HASH ANCHOR
/***************************************************************************/
if ( window.location.hash ) {
    $('html,body').animate({
        scrollTop: $(window.location.hash).offset().top - scrollPositionOffset + 'px'
    }, 1000);
}
    
    
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active-open");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight){
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    } 
  });
}


