import './app.scss'
import './js/adminbar.js'
import './js/site.js'

import Vue from 'vue'
import Components from './components'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  delimiters: ['${', '}']
})
