var adminDropdown = document.getElementById('adminBarDropdown');

if (adminDropdown) {
  adminDropdown.addEventListener('click', function(e){
    e.preventDefault();
    toggleAdminDropdown();
  });

  function toggleAdminDropdown() {
    document.getElementById("adminBarDropdownMenu").classList.toggle("ab-show");
  }

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.ab-dropdown-toggle')) {
      var dropdowns = document.getElementsByClassName("ab-dropdown-menu");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('ab-show')) {
          openDropdown.classList.remove('ab-show');
        }
      }
    }
  }
}